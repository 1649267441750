<template>
  <div class="user-details">
    <div class="usercontrol-first">
      <div class="usercontrol-first-left">
        <div class="usercontrol-first0" style="margin-right: 22px">
          <div class="usercontrol-first0-title">用户ID</div>
          <el-input
            v-model="controlValue"
            placeholder="请输入用户ID"
            clearable
          ></el-input>
        </div>
        <div class="usercontrol-first0">
          <div class="usercontrol-first0-title">用户手机号</div>
          <el-input
            v-model="controlPhone"
            placeholder="请输入用户手机号"
            clearable
          ></el-input>
        </div>
      </div>

      <div class="usercontrol-first-right">
        <div class="usercontrol-inquire" @click="detailsinquireBtn">查询</div>
      </div>
    </div>

    <!--第一模块 上级-->
    <div class="user-details-first">
      <div class="details-first-title">
        <div class="details-first-title-lint"></div>
        <div class="details-first-title-text">
          邀请用户id {{ userDetailsId }} 的用户
        </div>
      </div>
      <!--表格-->
      <div class="details-first-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#FAFAFA',
          }"
        >
          <el-table-column prop="uid" label="用户ID" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="levelName" label="用户等级" align="center">
            <template slot-scope="scope">
              <div class="userLevel">
                <div class="userLevel-title">
                  {{ scope.row.levelName }}
                </div>
                <img
                  class="userLevel-img"
                  :src="
                    scope.row.levelName == '普通会员'
                      ? require('../assets/user/lv1.png')
                      : scope.row.levelName == '白银会员'
                      ? require('../assets/user/lv2.png')
                      : scope.row.levelName == '黄金会员'
                      ? require('../assets/user/lv3.png')
                      : scope.row.levelName == '铂金会员'
                      ? require('../assets/user/lv4.png')
                      : require('../assets/user/lv5.png')
                  "
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="加入时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column prop="add_integral" label="累计积分" align="center">
          </el-table-column>
          <el-table-column prop="use_integral" label="可用积分" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="累计消费金额" align="center">
            <template slot-scope="scope"> ￥{{ scope.row.spend }} </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--第二模块 直推-->
    <div class="user-details-second">
      <div class="details-first-title">
        <div class="details-first-title-lint"></div>
        <div class="details-first-title-text">
          邀请用户id {{ userDetailsId }} 的用户
        </div>
      </div>
      <!--表格-->
      <div class="details-details-table">
        <el-table
          :data="tableDataDirect"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#FAFAFA',
          }"
        >
          <el-table-column prop="uid" label="用户ID" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="levelName" label="用户等级" align="center">
            <template slot-scope="scope">
              <div class="userLevel">
                <div class="userLevel-title">
                  {{ scope.row.levelName }}
                </div>
                <img
                  class="userLevel-img"
                  :src="
                    scope.row.levelName == '普通会员'
                      ? require('../assets/user/lv1.png')
                      : scope.row.levelName == '白银会员'
                      ? require('../assets/user/lv2.png')
                      : scope.row.levelName == '黄金会员'
                      ? require('../assets/user/lv3.png')
                      : scope.row.levelName == '铂金会员'
                      ? require('../assets/user/lv4.png')
                      : require('../assets/user/lv5.png')
                  "
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="加入时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column prop="add_integral" label="累计积分" align="center">
          </el-table-column>
          <el-table-column prop="use_integral" label="可用积分" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="累计消费金额" align="center">
            <template slot-scope="scope"> ￥{{ scope.row.spend }} </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="user-details-paging" v-if="DirectNum > 10">
        <el-pagination
          @size-change="handleSizeChangeDirect"
          @current-change="handleCurrentChangeDirect"
          :page-size="10"
          layout="prev, pager, next, sizes, jumper"
          :total="DirectNum"
          :pager-count="11"
        >
        </el-pagination>
      </div>
    </div>
    <!--第三模块 间推-->
    <div class="user-details-thirdly">
      <div class="details-first-title">
        <div class="details-first-title-lint"></div>
        <div class="details-first-title-text">
          被用户id {{ userDetailsId }} 间接邀请的用户
        </div>
      </div>
      <!--表格-->
      <div class="details-thirdly-table">
        <el-table
          :data="tableDataBetween"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#FAFAFA',
          }"
        >
          <el-table-column prop="uid" label="用户ID" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="levelName" label="用户等级" align="center">
            <template slot-scope="scope">
              <div class="userLevel">
                <div class="userLevel-title">
                  {{ scope.row.levelName }}
                </div>
                <img
                  class="userLevel-img"
                  :src="
                    scope.row.levelName == '普通会员'
                      ? require('../assets/user/lv1.png')
                      : scope.row.levelName == '白银会员'
                      ? require('../assets/user/lv2.png')
                      : scope.row.levelName == '黄金会员'
                      ? require('../assets/user/lv3.png')
                      : scope.row.levelName == '铂金会员'
                      ? require('../assets/user/lv4.png')
                      : require('../assets/user/lv5.png')
                  "
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="加入时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column prop="add_integral" label="累计积分" align="center">
          </el-table-column>
          <el-table-column prop="use_integral" label="可用积分" align="center">
          </el-table-column>
          <el-table-column prop="spend" label="累计消费金额" align="center">
            <template slot-scope="scope"> ￥{{ scope.row.spend }} </template>
          </el-table-column>
        </el-table>
      </div>

      <!--第三模块 分页列表-->
      <div class="user-details-paging" v-if="BetweenNum > 10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          layout="prev, pager, next, sizes, jumper"
          :total="BetweenNum"
          :pager-count="11"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import { formatDate } from '../utils/date'
import { userGetTeamUserid } from '../api/index'
export default {
  filters: {
    formatDate(time) {
      time = time * 1000 //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  data() {
    return {
      // 当前用户的id
      userDetailsId: '',
      // 表格数据 当前用户上级
      tableData: [],
      // 表格数据 当前用户直推
      tableDataDirect: [],
      // 表格数据 当前用户间推
      tableDataBetween: [],
      // 上级数据列表
      tableNum: 0,
      // 直推列表总条数
      DirectNum: 0,
      // 间推列表总条数
      BetweenNum: 0,
      // 搜索输入框
      controlValue: '',
      // 手机号输入框
      controlPhone: '',
      // 直推 页码
      detalisPage: 1,
      // 直推 页数
      detailsLimit: 10,

      // 间推 页码
      BetweenPage: 1,
      // 间推 页数
      BetweenLimit: 10,
      // 当前列表类型 direct直推人列表 indirect间推人列表 默认直推人
      listType: '',
    }
  },
  methods: {
    // 获取上个页面传递过来的id
    getDetails(id) {
      this.userDetailsId = id
      let params = {
        userId: this.userDetailsId, // 详情用户id
        uid: this.controlValue, // 检索用户id
        phone: this.controlPhone, // 检索用户手机号
        listType: this.listType, // 列表类型 direct直推人列表 indirect间推人列表 默认直推人
        pageSize: 10, // 	页码
        page: 1, // 页数
      }
      userGetTeamUserid(params).then((res) => {
        if (res.data.code == 200) {
          // 用户上级列表
          if (res.data.content.superiorsUser != '') {
            this.tableData.push(res.data.content.superiorsUser)
          }
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })

      this.getCount()
      this.getBetween()
    },
    // 同时请求直推数据接口
    getCount() {
      let params = {
        userId: this.userDetailsId, // 详情用户id
        uid: this.controlValue, // 检索用户id
        phone: this.controlPhone, // 检索用户手机号
        listType: 'direct', // 列表类型 direct直推人列表 indirect间推人列表 默认直推人
        pageSize: this.detailsLimit, // 	页码
        page: this.detalisPage, // 页数
      }
      userGetTeamUserid(params).then((res) => {
        if (res.data.code == 200) {
          console.log('获取直推人列表数据', res)
          // 用户直推列表
          this.tableDataDirect = res.data.content.userList
          this.DirectNum = res.data.content.totalNum
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },

    // 同时请求间推数据接口
    getBetween() {
      let params = {
        userId: this.userDetailsId, // 详情用户id
        uid: this.controlValue, // 检索用户id
        phone: this.controlPhone, // 检索用户手机号
        listType: 'indirect', // 列表类型 direct直推人列表 indirect间推人列表 默认直推人
        pageSize: this.BetweenLimit, // 	页码
        page: this.BetweenPage, // 页数
      }
      userGetTeamUserid(params).then((res) => {
        if (res.data.code == 200) {
          console.log('获取直推人列表数据', res)
          // 用户间推列表
          this.tableDataBetween = res.data.content.userList
          this.BetweenNum = res.data.content.totalNum
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 查询
    detailsinquireBtn() {
      this.detalisPage = 1
      this.BetweenPage = 1

      this.getCount()
      this.getBetween()
    },
    // 直推获取当前每页条数
    handleSizeChangeDirect(val) {
      console.log(`每页 ${val} 条`)
    },
    // 直推获取当前页码
    handleCurrentChangeDirect(val) {
      this.detalisPage = val
      this.getCount()
    },
    // 间推获取当前每页条数
    handleSizeChangeBetween(val) {
      console.log(`每页 ${val} 条`)
    },
    // 间推获取当前页码
    handleCurrentChangeBetween(val) {
      this.BetweenPage = val
      this.getBetween()
    },
  },
}
</script>

<style lang="less" scoped>
.user-details {
  .usercontrol-first {
    display: flex;
    height: 80px;
    background: #ffffff;
    border-radius: 5px;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 10px;

    .usercontrol-first-left {
      display: flex;
      align-items: center;
      height: 40px;

      .usercontrol-first0 {
        display: flex;
        height: 40px;
        align-items: center;

        .usercontrol-first0-title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
        }

        /deep/ .el-input {
          width: 200px;
        }

        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        /deep/ .el-input__icon {
          line-height: 32px;
        }
      }
    }

    .usercontrol-first-right {
      height: 40px;
      display: flex;
      align-items: center;

      .usercontrol-inquire {
        width: 60px;
        height: 32px;
        background: #29d647;
        border-radius: 5px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        margin-left: 40px;
      }
    }
  }

  .user-details-first,
  .user-details-second,
  .user-details-thirdly {
    padding: 24px;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 30px;
    .details-first-title {
      display: flex;
      align-items: center;
      height: 50px;

      .details-first-title-lint {
        width: 3px;
        height: 15px;
        background: #29d647;
      }
      .details-first-title-text {
        margin-left: 21px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
      }
    }

    .userLevel {
      display: flex;
      align-items: center;
      justify-content: center;

      .userLevel-title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
      }

      .userLevel-img {
        margin-left: 10px;
        width: 42px;
        height: 16px;
        border-radius: 8px;
      }
    }

    .details-thirdly-table {
      display: flex;
    }

    .details-details-table {
      display: flex;
    }

    /deep/ .el-table th.el-table__cell > .cell {
      color: #565656;
    }
  }

  .user-details-paging {
    height: 58px;
    width: 100%;

    /deep/ .el-pagination {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }

    /deep/ .el-pager li {
      width: 32px;
      height: 32px;
      padding: 0;
      line-height: 32px;
      margin-right: 8px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }

    /deep/ .el-pager li.active {
      background: #eafbed;
      border-radius: 4px;
      color: #29d647;
    }

    /deep/ .el-pager li:hover {
      color: #29d647;
    }

    /deep/ .el-pagination .btn-prev {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
    }

    /deep/.el-pagination .btn-next {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
    }

    /deep/ .el-pagination button:hover {
      color: #29d647;
    }
  }
}
</style>
