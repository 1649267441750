<template>
    <div class="usercontrol">
        <div v-if="userShow == false">
            <!--用户管理  第一模块-->
            <div class="usercontrol-first">
                <div class="usercontrol-first-left">
                    <div class="usercontrol-first0" style="margin-right: 22px">
                        <div class="usercontrol-first0-title">用户ID</div>
                        <el-input v-model="controlValue" placeholder="请输入用户ID" clearable></el-input>
                    </div>
                    <div class="usercontrol-first0">
                        <div class="usercontrol-first0-title">用户手机号</div>
                        <el-input v-model="controlPhone" placeholder="请输入用户手机号" clearable></el-input>
                    </div>
                </div>

                <div class="usercontrol-first-right">
                    <div class="usercontrol-inquire" @click="inquireBtn">查询</div>
                </div>
            </div>
            <!--用户管理  第二模块-->
            <div class="usercontrol-second">
                <el-table :data="tableData" style="width: 100%" :header-cell-style="{
            'text-align': 'center',
            background: '#FAFAFA',
        }">
                    <el-table-column prop="uid" label="用户ID" align="center">
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" label="用户等级" align="center">
                        <template slot-scope="scope">
                            <div class="userLevel">
                                <div class="userLevel-title">
                                    {{ scope.row.collaborateStatus == 1 ? scope.row.collaborateLevel == 1 ? '初级合伙人' :
            scope.row.collaborateLevel == 2 ? '精英合伙人' : scope.row.collaborateLevel == 3 ?
                '事业合伙人' : '' :
            '普通用户' }}
                                </div>
                                <!-- <img class="userLevel-img" :src="scope.row.levelName == '普通会员'
                                    ? require('../assets/user/lv1.png')
                                    : scope.row.levelName == '白银会员'
                                        ? require('../assets/user/lv2.png')
                                        : scope.row.levelName == '黄金会员'
                                            ? require('../assets/user/lv3.png')
                                            : scope.row.levelName == '铂金会员'
                                                ? require('../assets/user/lv4.png')
                                                : require('../assets/user/lv5.png')
                                    " /> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买VIP" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.isVip == 0 ? '否' : '是' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="inviteUserNum" label="邀请用户（人）" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="加入时间" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.createTime }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="add_integral" label="累计积分" align="center">
                    </el-table-column>
                    <el-table-column prop="use_integral" label="可用积分" align="center">
                    </el-table-column>
                    <el-table-column prop="spend" label="累计消费金额" align="center">
                        <template slot-scope="scope"> ￥{{ scope.row.spend }} </template>
                    </el-table-column>
                    <!--     <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="operation">

                                <div class="examine" @click="examineBtn(scope.row.uid)">
                                    <img class="examine-img" src="../assets/earnings/order.png" />
                                    <div class="examine-text">查看</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>-->
                </el-table>
            </div>

            <!--用户管理 第三模块  分页列表-->
            <div class="headline-paging" v-if="tableNum > 10">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="10"
                    layout="prev, pager, next, sizes, jumper" :total="tableNum" :pager-count="11">
                </el-pagination>
            </div>

            <!--用户修改表单提示框-->
            <!-- <div class="earning-compile">
        <el-dialog
          title="修改"
          :visible.sync="compileVisible"
          width="444px"
          :before-close="handleClose"
        >
          <div class="compile-popup">
            <div class="compile-popup-first">
              <div class="compile-popup-first-lint"></div>
              <div class="compile-popup-first-text">原绑定</div>
            </div>
            <div class="compile-popup-second">
              <el-table
                :data="compileArray"
                style="width: 100%"
                :header-cell-style="{
                  'text-align': 'center',
                  background: '#FFFFFF',
                }"
              >
                <el-table-column prop="uid" label="用户ID" align="center">
                </el-table-column>
                <el-table-column prop="phone" label="手机号" align="center">
                </el-table-column>
                <el-table-column
                  prop="userLevel"
                  label="用户等级"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="userLevel">
                      <div class="userLevel-title">
                        {{
                          scope.row.userLevel == 1
                            ? '普通会员'
                            : scope.row.userLevel == 2
                            ? '白银会员'
                            : scope.row.userLevel == 3
                            ? '黄金会员'
                            : scope.row.userLevel == 4
                            ? '铂金会员'
                            : scope.row.userLevel == 5
                            ? '钻石会员'
                            : ''
                        }}
                      </div>
                      <img
                        class="userLevel-img"
                        :src="
                          scope.row.userLevel == 1
                            ? require('../assets/user/lv1.png')
                            : scope.row.userLevel == 2
                            ? require('../assets/user/lv2.png')
                            : scope.row.userLevel == 3
                            ? require('../assets/user/lv3.png')
                            : scope.row.userLevel == 4
                            ? require('../assets/user/lv4.png')
                            : require('../assets/user/lv5.png')
                        "
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="compile-popup-first" style="margin-top: 20px">
              <div class="compile-popup-first-lint"></div>
              <div class="compile-popup-first-text">将绑定</div>
            </div>
            <div class="compile-popup-thirdly">
              <div class="compile-popup-thirdly-first0">
                <el-input
                  v-model="compileId"
                  placeholder="请输入用户ID/手机号/订单号"
                  suffix-icon="el-icon-search"
                ></el-input>
              </div>
              <div
                class="compile-popup-thirdly-inquire"
                @click="compileinquireBtn"
              >
                查询
              </div>
            </div>
            <div class="compile-popup-div">
              <div class="compile-popup-div-title">—— 搜索结果 ——</div>
              <div class="compile-popup-div-table">
                <el-table
                  ref="multipleTable"
                  :data="tableDataSearch"
                  tooltip-effect="dark"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                  :header-cell-class-name="cellClass"
                  :header-cell-style="{
                    'text-align': 'center',
                    background: '#FAFAFA',
                  }"
                >
                  <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="uid"
                    label="用户ID"
                    align="center"
                    width="80"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="phone"
                    label="手机号"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="userLevel"
                    label="用户等级"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div class="userLevel">
                        <div class="userLevel-title">
                          {{ scope.row.userLevel }}
                        </div>
                        <img
                          class="userLevel-img"
                          :src="
                            scope.row.userLevel == 1
                              ? require('../assets/user/lv1.png')
                              : scope.row.userLevel == 2
                              ? require('../assets/user/lv2.png')
                              : scope.row.userLevel == 3
                              ? require('../assets/user/lv3.png')
                              : scope.row.userLevel == 4
                              ? require('../assets/user/lv4.png')
                              : require('../assets/user/lv5.png')
                          "
                        />
                      </div> </template
                  ></el-table-column>
                </el-table>
              </div>
            </div>

            <div class="compile-popup-affirm">
              <div
                class="compile-popup-affirm-botton"
                @click="compileAffirmBtn"
              >
                确认
              </div>
            </div>
          </div>
        </el-dialog>
      </div> -->
        </div>

        <div v-else class="details">
            <user-details ref="child"></user-details>
        </div>

        <!--用户搜索提示框-->
        <div class="earning-dialog">
            <el-dialog :visible.sync="dialogVisible" width="394px" :before-close="handleClose" center
                style="margin-top: 20vh">
                <div class="earning-dialog-div">
                    <img class="earning-dialog-div-img" src="../assets/earnings/warning.png" />
                    <div class="earning-dialog-div-title">
                        抱歉，此用户不存在或不在团队中！
                    </div>

                    <div class="earning-dialog-div-display">
                        <div class="earning-dialog-div-display-cancel" @click="cancelBtn">
                            取消
                        </div>
                        <div class="earning-dialog-div-display-affirm" @click="affirmBtn">
                            确定
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!--公共底部模块-->
        <bottom></bottom>
    </div>
</template>

<script>
import userDetails from '../components/user-details.vue'
import bottom from '../components/bottom.vue'
import { userGetTeamUser } from '../api/index'
import { formatDate } from '../utils/date'
export default {
    components: {
        userDetails,
        bottom,
    },
    filters: {
        formatDate(time) {
            time = time * 1000 //注意：time应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
        },
    },
    data() {
        return {
            // 用户id
            controlValue: '',
            // 用户手机号
            controlPhone: '',
            // 用户管理表格
            tableData: [],
            // 搜索弹出框
            dialogVisible: false,
            // 用户管理 of 用户详情
            userShow: false,
            // 用户点击修改按钮弹出框
            compileVisible: false,
            // 修改弹出框按钮第一模块的表格数组
            compileArray: [
                {
                    uid: '123',
                    phone: '17683988549',
                    userLevel: 1,
                },
            ],
            // 修改弹出框搜索将绑定id
            compileId: '',
            // 修改弹出框搜索结果的表格
            tableDataSearch: [
                {
                    uid: '123',
                    phone: '17683988549',
                    userLevel: 1,
                },
            ],
            multipleSelection: [],
            // 团队人数列表条数
            tableNum: -1,
            // 页码
            page: 1,
            // 条数
            limit: 10,
        }
    },
    created() {
        this.GetTeamUser()
    },
    methods: {
        // 获取列表数据
        GetTeamUser() {
            let params = {
                uid: this.controlValue,
                phone: this.controlPhone,
                createStartTime: '',
                createEndTime: '',
                pageSize: this.limit,
                page: this.page,
            }
            userGetTeamUser(params).then((res) => {
                console.log('列表数据', res)
                if (res.data.code == 200) {
                    // 用户列表数据
                    this.tableData = res.data.content.userList
                    // 用户列表条数
                    this.tableNum = res.data.content.totalNum

                    if (this.tableNum == 0) {
                        if (this.controlValue != '' || this.controlPhone != '') {
                            this.dialogVisible = true
                        } else {
                            this.dialogVisible = false
                        }
                    }
                } else {
                    // 退出登陆 清除用户资料
                    localStorage.clear()
                    // 重设路由
                    resetRouter()
                }
            })
        },
        // 点击操作修改按钮
        modificationBtn(row) {
            console.log('修改按钮', row)
            this.compileVisible = true
        },
        // 点击操作查看按钮
        examineBtn(row) {
            this.userShow = true
            this.$nextTick(() => {
                this.$refs.child.getDetails(row)
            })
        },
        // 获取当前每页条数
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
        },
        // 获取当前页码
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.page = val
            this.GetTeamUser()
        },
        // 用户点击查询搜索
        inquireBtn() {
            this.GetTeamUser()
        },
        // 点击关闭弹出框
        handleClose() {
            console.log('点击关闭')
            this.dialogVisible = false
            this.compileVisible = false
        },
        // 用户点击取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 用户点击确认
        affirmBtn() {
            this.dialogVisible = false
        },
        // 修改弹出框部分操作

        // 隐藏表头中的全选框
        cellClass(row) {
            console.log(row)
            if (row.columnIndex === 0) {
                return 'disabledCheck'
            }
        },
        // 修改弹出框内部点击查询按钮
        compileinquireBtn() {
            console.log('点击查询', this.compileId)
        },

        // 修改弹出框内部点击确认按钮
        compileAffirmBtn() {
            console.log('点击确认修改按钮')
            this.compileVisible = false
        },

        // 选中对应的数据
        handleSelectionChange(val) {
            console.log('打印对应的数据', this.multipleSelection)
            this.multipleSelection = val
        },
    },
}
</script>

<style lang="less" scoped>
.usercontrol {
    margin: 0 20px;

    .usercontrol-first {
        display: flex;
        height: 80px;
        background: #ffffff;
        border-radius: 5px;
        align-items: center;
        padding: 0 24px;
        box-sizing: border-box;
        justify-content: space-between;

        .usercontrol-first-left {
            display: flex;
            align-items: center;
            height: 40px;

            .usercontrol-first0 {
                display: flex;
                height: 40px;
                align-items: center;

                .usercontrol-first0-title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 10px;
                }

                /deep/ .el-input {
                    width: 200px;
                }

                /deep/ .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                }

                /deep/ .el-input__icon {
                    line-height: 32px;
                }
            }
        }

        .usercontrol-first-right {
            height: 40px;
            display: flex;
            align-items: center;

            .usercontrol-inquire {
                width: 60px;
                height: 32px;
                background: #29d647;
                border-radius: 5px;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
                margin-left: 40px;
            }
        }
    }

    .usercontrol-second {
        margin-top: 10px;
        padding: 24px;
        background: #ffffff;
        box-sizing: border-box;

        /deep/ .el-table th.el-table__cell>.cell {
            color: #565656;
        }

        /deep/ .el-table th.el-table__cell>.cell {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #565656;
        }

        /deep/ .el-table {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #717171;
        }

        .earningStatusColor {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .userLevel {
            display: flex;
            align-items: center;
            justify-content: center;

            .userLevel-title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #888888;
            }

            .userLevel-img {
                margin-left: 10px;
                width: 42px;
                height: 16px;
                border-radius: 8px;
            }
        }

        .operation {
            display: flex;
            justify-content: center;
            cursor: pointer;

            //   .modification {
            //     display: flex;
            //     align-items: center;
            //     height: 20px;

            //     .modification-img {
            //       width: 16px;
            //       height: 16px;
            //     }
            //     .modification-text {
            //       font-size: 14px;
            //       font-family: PingFangSC-Regular, PingFang SC;
            //       font-weight: 400;
            //       color: #32d74e;
            //       margin-left: 6px;
            //     }
            //   }

            .examine {
                display: flex;
                align-items: center;
                height: 20px;
                // margin-left: 39px;

                .examine-img {
                    width: 16px;
                    height: 16px;
                }

                .examine-text {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    margin-left: 6px;
                }
            }
        }
    }

    .headline-paging {
        height: 102px;
        width: 100%;
        background: #ffffff;
        padding-right: 30px;

        /deep/ .el-pagination {
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;
        }

        /deep/ .el-pager li {
            width: 32px;
            height: 32px;
            padding: 0;
            line-height: 32px;
            margin-right: 8px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
        }

        /deep/ .el-pager li.active {
            background: #eafbed;
            border-radius: 4px;
            color: #29d647;
        }

        /deep/ .el-pager li:hover {
            color: #29d647;
        }

        /deep/ .el-pagination .btn-prev {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            margin-right: 8px;
        }

        /deep/.el-pagination .btn-next {
            width: 32px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            margin-right: 8px;
        }

        /deep/ .el-pagination button:hover {
            color: #29d647;
        }
    }

    .earning-dialog {
        /deep/ .el-dialog {
            height: 248px;
        }

        /deep/ .el-dialog__header {
            padding: 0;
        }

        .earning-dialog-div {
            text-align: center;

            .earning-dialog-div-img {
                width: 49px;
                height: 49px;
                margin: 0 auto;
            }

            .earning-dialog-div-title {
                height: 32px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                line-height: 32px;
                margin-top: 5px;
            }

            .earning-dialog-div-display {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 44px;

                .earning-dialog-div-display-cancel,
                .earning-dialog-div-display-affirm {
                    width: 88px;
                    height: 32px;
                    background: #ffffff;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    line-height: 32px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.65);
                    cursor: pointer;
                }

                .earning-dialog-div-display-affirm {
                    margin-left: 8px;
                    background: #29d647;
                    color: #ffffff;
                }
            }
        }
    }

    // 点击修改按钮弹出框
    .earning-compile {
        /deep/ .el-dialog__header {
            padding: 0;
            height: 55px;
            display: flex;
            align-items: center;
            padding: 0 24px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }

        /deep/ .el-dialog__title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
        }

        s /deep/ .el-dialog__body {
            padding: 21px 26px 10px;
        }

        /*鼠标移入某行时的背景色*/
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #fff;
        }

        /* table 加fix 鼠标移入某行时的背景色 */
        /deep/ .el-table__body tr.hover-row.current-row>td,
        .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
        .el-table__body tr.hover-row.el-table__row--striped>td,
        .el-table__body tr.hover-row>td {
            background-color: #fff;
        }

        /deep/ .el-table th.is-leaf {
            /* 去除上边框 */
            border: none;
        }

        /deep/ .el-table::before {
            /* 去除下边框 */
            height: 0;
        }

        .compile-popup {
            .compile-popup-first {
                display: flex;
                height: 20px;
                align-items: center;

                .compile-popup-first-lint {
                    width: 3px;
                    height: 15px;
                    background: #29d647;
                }

                .compile-popup-first-text {
                    margin-left: 10px;
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #222222;
                }
            }

            .compile-popup-second {
                /deep/ .el-table th.el-table__cell>.cell {
                    padding-left: 0;
                }

                .userLevel {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .userLevel-title {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #888888;
                    }

                    .userLevel-img {
                        margin-left: 10px;
                        width: 42px;
                        height: 16px;
                        border-radius: 8px;
                    }
                }
            }

            .compile-popup-thirdly {
                display: flex;
                height: 32px;
                align-items: center;
                margin-top: 12px;

                .compile-popup-thirdly-first0 {
                    /deep/ .el-input {
                        width: 280px;
                    }

                    /deep/ .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }

                    /deep/ .el-input__icon {
                        line-height: 32px;
                    }
                }

                .compile-popup-thirdly-inquire {
                    width: 60px;
                    height: 32px;
                    background: #29d647;
                    border-radius: 5px;
                    line-height: 32px;
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    cursor: pointer;
                    margin-left: 6px;
                }
            }

            .compile-popup-div {
                width: 100%;
                text-align: center;
                margin-top: 12px;

                .compile-popup-div-title {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #d9d9d9;
                }

                .compile-popup-div-table {

                    /* 去掉全选按钮 */
                    /deep/ .el-table .disabledCheck .cell .el-checkbox__inner {
                        display: none !important;
                    }

                    /deep/ .el-table .disabledCheck .cell::before {
                        content: '选择';
                        text-align: center;
                        line-height: 37px;
                    }

                    /deep/ .el-table th.el-table__cell>.cell {
                        color: #565656;
                    }

                    .userLevel {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .userLevel-title {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #888888;
                        }

                        .userLevel-img {
                            margin-left: 10px;
                            width: 42px;
                            height: 16px;
                            border-radius: 8px;
                        }
                    }
                }
            }

            .compile-popup-affirm {
                width: 100%;
                height: 32px;
                margin-top: 37px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .compile-popup-affirm-botton {
                    cursor: pointer;
                    width: 65px;
                    height: 32px;
                    background: #29d647;
                    border-radius: 2px;
                    text-align: center;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }

    .details {
        margin-top: 10px;
    }
}
</style>
